import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { couponUseService, couponService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabCouponUse(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        coupon: null,
    })

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'coupon',
            service: couponService.combo,
            col: 3,
        },
    ]

    const { t } = useTranslation()

    const paintCell = {
        total: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
        paymentState: (value, object) => {
            if (value) {
                if (value == 'PAYMENT') return <>PAGADO</>
                else if (value == 'ERROR') return <>ERROR EN EL PAGO</>
                else if (value == 'PARCIAL') return <>PAGADO PARCIALMENTE</>
                else return <></>
            } else {
                return <></>
            }
        },
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            coupon: null,
            user: props.idclient,
        })
    }, [props.idclient])

    return (
        <>
            <CrudTab
                service={couponUseService}
                urledit={''}
                urlnew={''}
                editable={false}
                deleteaction={false}
                filter={filter}
                i18n={'couponuse'}
                fieldstable={['createDate', 'coupon.code', 'coupon.name']}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
