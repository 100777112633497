import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    shareService as service,
    cartypeService,
    carService,
    userService,
    couponService,
    pricesService,
    rentalService,
    companyService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    Autocomplete,
    TextField,
    Grid,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { ControlRouteMap } from '../../utils/ControlRouteMap'

const initElement = {
    car: { id: -1, name: '' },
    carTypes: { id: -1, name: '' },
    prices: { id: -1, name: '' },
    user: { id: -1, name: '' },
    coupon: { id: -1, name: '' },
    zone: { id: -1, name: '' },
    company: { id: -1, name: '' },
    startReserve: '',
    start: '',
    end: '',
    start_real: '',
    end_real: '',
    lat_point_start: 0.0,
    lng_point_start: 0.0,
    lat_point_end: 0.0,
    lng_point_end: 0.0,
    price: 0.0,
    total: 0.0,
    km_inlude: 0.0,
    km_real: 0.0,
    discount: 0.0,
    totalPay: 0.0,
    km_start: '',
    fuel_level_start: '',
    km_end: '',
    fuel_level_end: '',
    status_start: '',
    status_end: '',
    sharedPay: '',
    stree: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    rentalState: '',
    paymentState: '',
    pin: '',
    lines: [],
    tokenorder: '',
    paymentData: { id: -1, credit_number: '' },
    seguro: false,
    minor25: false,
    minor3: false,
    tokenFiance: '',
}

const initElementLine = {
    concept: '',
    price: 0.0,
    ammount: 0.0,
    total: 0.0,
}

export default function FormShare() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const { t, i18n } = useTranslation()

    var line = {
        concept: '',
        price: 0,
    }

    var stateForm = {
        openConfirm: false,
        openCancel: false,
        openCar: false,
        openAddline: false,
        carsOptions: [],
        carSelect: { id: -1, name: '' },
        addline: { ...line },
    }

    const fieldmap = (field, valueform, objectform, setSate, setValueField) => {
        if (
            objectform['id'] &&
            objectform['id'] > 0 &&
            objectform['lng_point_start'] &&
            objectform['lat_point_start']
        ) {
            return (
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        center={{
                            lat: objectform['lat_point_start'],
                            lng: objectform['lng_point_start'],
                        }}
                        zoom={18}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        styles={[
                            {
                                featureType: 'poi',
                                stylers: [{ visibility: 'off' }],
                            },
                        ]}
                    >
                        <Marker
                            position={{
                                lat: objectform['lat_point_start'],
                                lng: objectform['lng_point_start'],
                            }}
                            title={objectform['id'] + 'start'}
                            icon={'/marker.png'}
                        />

                        {objectform['lat_point_end'] &&
                            objectform['lng_point_end'] && (
                                <Marker
                                    position={{
                                        lat: objectform['lat_point_end'],
                                        lng: objectform['lng_point_end'],
                                    }}
                                    title={objectform['id'] + 'end'}
                                    icon={'/markerFree.png'}
                                />
                            )}
                    </Map>

                    <ControlRouteMap route={objectform['routeUser']} />
                </APIProvider>
            )
        } else {
            return <></>
        }
    }

    const estructureformLine = [
        {
            type: 'input',
            name: 'concept',
            colSpan: '40%',
            col: 4,
        },
        {
            type: 'input',
            name: 'ammount',
            inputtype: 'number',
            step: 0.01,
            colSpan: '20%',
            col: 4,
        },
        {
            type: 'input',
            name: 'price',
            inputtype: 'number',
            step: 0.01,
            colSpan: '20%',
            col: 4,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            step: 0.01,
            colSpan: '20%',
            readonly: true,
            col: 4,
        },
    ]

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'user.id',
            colSpan: '50%',
            service: userService.combo,
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'company.id',
            colSpan: '50%',
            service: companyService.combo,
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'input',
            name: 'startReserve',
            inputtype: 'datetime-local',
            readonly: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 2,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 2,
        },
        {
            type: 'title',
            name: 'titlevehicle',
            col: 12,
        },
        {
            type: 'input',
            name: 'car.number',
            readonly: true,
            col: 4,
        },
        {
            type: 'title',
            name: 'titlelocation',
            col: 12,
        },
        {
            type: 'input',
            name: 'zone.name',
            col: 4,
        },
        {
            type: 'input',
            name: 'km_start',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'km_end',
            col: 4,
            readonly: true,
        },
        {
            type: 'title',
            name: 'othersinfo',
            col: 12,
        },
        {
            type: 'checkbox',
            name: 'seguro',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'minor25',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'minor3',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'input',
            name: 'coupon.name',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'rentalState',
            options: [
                { key: 'START', name: 'INICIADO' },
                { key: 'END', name: 'FINALIZADO' },
                { key: 'ISSUE', name: 'CON INCIDENCIA' },
                { key: 'CANCEL', name: 'CANCELADO' },
                { key: 'RESERVED', name: 'RESERVADO' },
                { key: 'CONFIRMRESERVED', name: 'RESERVA CONFIRMADA' },
                { key: 'ASSIGNCAR', name: 'COCHE ASIGNADO' },
            ],
            readonly: true,
            col: 3,
        },
        {
            type: 'title',
            name: 'titlepay',
            col: 12,
        },
        {
            type: 'input',
            name: 'km_real',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'min_standby',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'totalPay',
            inputtype: 'number',
            col: 3,
        },
        {
            type: 'input',
            name: 'tokenFiance',
            readonly: true,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'paymentState',
            options: [
                { key: 'PAYMENT', name: 'PAGADO' },
                { key: 'ERROR', name: 'ERROR EN EL PAGO' },
                { key: 'PARCIAL', name: 'PAGO PARCIAL' },
                { key: 'NONE', name: 'NO REALIZADO' },
            ],
            col: 3,
        },
        {
            type: 'input',
            name: 'paymentData.credit_number',
            readonly: true,
            col: 3,
        },
        {
            type: 'title',
            name: 'titlelines',
            col: 12,
        },
        {
            type: 'table',
            name: 'lines',
            headers: [
                { label: 'Concepto', colSpan: '40%' },
                { label: 'Cantidad', colSpan: '20%' },
                { label: 'Precio (CON IVA)', colSpan: '20%' },
                { label: 'Total', colSpan: '20%' },
            ],
            initrow: { ...initElementLine },
            fields: estructureformLine,
            fieldId: 'id',
            labelbtn: 'Añadir gasto',
            onlyread: true,
            col: 8,
        },
        {
            type: 'custom',
            name: 'route',
            custom: fieldmap,
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['car'] =
            element['car'] && element['car']['id']
                ? element['car']
                : { id: -1, name: '' }
        objectedit['carTypes'] =
            element['carTypes'] && element['carTypes']['id']
                ? element['carTypes']
                : { id: -1, name: '' }
        objectedit['prices'] =
            element['prices'] && element['prices']['id']
                ? element['prices']
                : { id: -1, name: '' }
        objectedit['user'] =
            element['user'] && element['user']['id']
                ? element['user']
                : { id: -1, name: '' }
        objectedit['coupon'] =
            element['coupon'] && element['coupon']['id']
                ? element['coupon']
                : { id: -1, name: '' }
        objectedit['zone'] =
            element['zone'] && element['zone']['id']
                ? element['zone']
                : { id: -1, name: '' }
        objectedit['startReserve'] = element['startReserve']
            ? element['startReserve']
            : ''

        objectedit['start'] = element['start'] ? element['start'] : ''
        objectedit['end'] = element['end'] ? element['end'] : ''
        objectedit['start_real'] = element['start_real']
            ? element['start_real']
            : ''
        objectedit['end_real'] = element['end_real'] ? element['end_real'] : ''
        objectedit['lat_point_start'] = element['lat_point_start']
            ? element['lat_point_start']
            : 0.0
        objectedit['lng_point_start'] = element['lng_point_start']
            ? element['lng_point_start']
            : 0.0
        objectedit['lat_point_end'] = element['lat_point_end']
            ? element['lat_point_end']
            : 0.0
        objectedit['lng_point_end'] = element['lng_point_end']
            ? element['lng_point_end']
            : 0.0
        objectedit['price'] = element['price'] ? element['price'] : 0.0
        objectedit['total'] = element['total'] ? element['total'] : 0.0
        objectedit['km_inlude'] = element['km_inlude']
            ? element['km_inlude']
            : 0.0
        objectedit['km_real'] = element['km_real'] ? element['km_real'] : 0.0
        objectedit['discount'] = element['discount'] ? element['discount'] : 0.0
        objectedit['totalPay'] = element['totalPay'] ? element['totalPay'] : 0.0
        objectedit['km_start'] = element['km_start'] ? element['km_start'] : ''
        objectedit['fuel_level_start'] = element['fuel_level_start']
            ? element['fuel_level_start']
            : ''
        objectedit['km_end'] = element['km_end'] ? element['km_end'] : ''
        objectedit['fuel_level_end'] = element['fuel_level_end']
            ? element['fuel_level_end']
            : ''
        objectedit['status_start'] = element['status_start']
            ? element['status_start']
            : ''
        objectedit['status_end'] = element['status_end']
            ? element['status_end']
            : ''
        objectedit['sharedPay'] = element['sharedPay']
            ? element['sharedPay']
            : ''
        objectedit['stree'] = element['stree'] ? element['stree'] : ''
        objectedit['city'] = element['city'] ? element['city'] : ''
        objectedit['state'] = element['state'] ? element['state'] : ''
        objectedit['country'] = element['country'] ? element['country'] : ''
        objectedit['postalCode'] = element['postalCode']
            ? element['postalCode']
            : ''
        objectedit['rentalState'] = element['rentalState']
            ? element['rentalState']
            : ''
        objectedit['paymentState'] = element['paymentState']
            ? element['paymentState']
            : ''
        objectedit['pin'] = element['pin'] ? element['pin'] : ''

        objectedit['lines'] = element['lines'] ? element['lines'] : []

        objectedit['tokenorder'] = element['tokenorder']
            ? element['tokenorder']
            : ''

        objectedit['paymentData'] =
            element['paymentData'] && element['paymentData']['id']
                ? element['paymentData']
                : { id: -1, name: '' }

        objectedit['seguro'] = element['seguro'] ? element['seguro'] : false
        objectedit['minor25'] = element['minor25'] ? element['minor25'] : false
        objectedit['minor3'] = element['minor3'] ? element['minor3'] : false
        objectedit['tokenFiance'] = element['tokenFiance']
            ? element['tokenFiance']
            : ''

        return objectedit
    }

    const isValid = function (object) {
        if (!object['user'] || !object['user']['id']) {
            return t('rent.error.user')
        }

        return null
    }

    const sendCancel = () => {
        service.finish(id, (data, error) => {
            window.location.reload()
        })
    }

    const addLine = (line) => {
        service.addline(id, line, (data, error) => {
            window.location.reload()
        })
    }

    const createBillSend = (id) => {
        service.generateBill(id, (data, error) => {
            if (data && data.data && data.data.id) {
                window.location.href = '/bill/edit/' + data.data.id
            }
        })
    }

    const closeCar = (id) => {
        carService.closedoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const openCar = (id) => {
        carService.opendoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const moreActions = (getObject, state, saveState) => {
        var object = getObject()
        var isNotFinish = id && object.rentalState == 'START'
        var createBill = id && !object.idBill && object.rentalState == 'END'
        var showBill = id && object.idBill && object.rentalState == 'END'
        var isStart = id && object.rentalState == 'START'
        var isEnd = id && object.rentalState == 'END'

        var isCompany =
            id && object.company != null && object.company.id != null

        var isCar =
            id &&
            (object.rentalState == 'CONFIRMRESERVED' ||
                object.rentalState == 'ASSIGNCAR' ||
                object.rentalState == 'START')

        return (
            <>
                {id && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            debugger
                            window.open(
                                '/users/edit/' + object.user.id,
                                '_blank'
                            )
                        }}
                    >
                        {' '}
                        Ver usuario
                    </Button>
                )}
                {object.car && object.car.id && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            debugger
                            window.open('/car/edit/' + object.car.id, '_blank')
                        }}
                    >
                        {' '}
                        Ver coche
                    </Button>
                )}
                {isNotFinish && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            debugger
                            setStateInParent('openCancel', true, saveState)
                        }}
                    >
                        {' '}
                        Finalizar
                    </Button>
                )}
                {createBill && !isCompany && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            createBillSend(object.id)
                        }}
                    >
                        {' '}
                        Crear factura
                    </Button>
                )}
                {showBill && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            window.location.href = '/bill/edit/' + object.idBill
                        }}
                    >
                        {' '}
                        Ver factura
                    </Button>
                )}

                {isStart && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            openCar(object.car.id)
                        }}
                    >
                        {' '}
                        Abrir puertas
                    </Button>
                )}

                {isStart && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            closeCar(object.car.id)
                        }}
                    >
                        {' '}
                        Cerrar puertas
                    </Button>
                )}

                {isEnd && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            setStateInParent('openAddline', true, saveState)
                        }}
                    >
                        {' '}
                        Añadir deuda
                    </Button>
                )}
            </>
        )
    }

    const initeffect = (saveState) => {
        carService.combo((data, error) => {
            setStateInParent('carsOptions', data.data, saveState)
        })
    }

    const setStateInParent = (name, val, call) => {
        debugger
        if (val) {
            call(name, val)
        } else {
            call(name, val)
        }
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'share'}
                urledit={'/share/edit/'}
                urlCancel={'/share'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                moreActions={moreActions}
                initeffect={initeffect}
                tabs={[]}
                stateform={stateForm}
                haveInside={(state, saveState) => {
                    return (
                        <>
                            <Modal
                                open={state.openCancel}
                                onClose={() =>
                                    setStateInParent(
                                        'openCancel',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Estas seguro que deseas FIANLIZAR la
                                        reserva?
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openCancel',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            No
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={sendCancel}
                                        >
                                            {' '}
                                            Sí{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                            <Modal
                                open={state.openAddline}
                                onClose={() =>
                                    setStateInParent(
                                        'openAddline',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Quieres añadir una deuda?
                                    </div>
                                    <Grid container>
                                        <Grid xs={12}>
                                            {' '}
                                            <TextField
                                                variant="standard"
                                                sx={{ width: '100%' }}
                                                id={'concept'}
                                                name={'concept'}
                                                label={'Concepto'}
                                                value={state.addline.concept}
                                                onChange={(evt) => {
                                                    var aux = {
                                                        ...state.addline,
                                                    }
                                                    aux.concept =
                                                        evt.target.value

                                                    setStateInParent(
                                                        'addline',
                                                        aux,
                                                        saveState
                                                    )
                                                }}
                                                type={'text'}
                                                autoComplete="off"
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <br />
                                        </Grid>
                                        <Grid xs={12}>
                                            <TextField
                                                variant="standard"
                                                sx={{ width: '100%' }}
                                                id={'price'}
                                                name={'price'}
                                                label={'Precio'}
                                                value={state.addline.price}
                                                onChange={(evt) => {
                                                    var aux = {
                                                        ...state.addline,
                                                    }
                                                    aux.price =
                                                        evt.target.value == ''
                                                            ? null
                                                            : +evt.target.value

                                                    setStateInParent(
                                                        'addline',
                                                        aux,
                                                        saveState
                                                    )
                                                }}
                                                type={'number'}
                                                inputProps={{
                                                    step: 0.01,
                                                    autoComplete: 'off',
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid xs={12}>
                                            <br />
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openAddline',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                addLine(state.addline)
                                            }
                                        >
                                            {' '}
                                            Añadir{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </>
                    )
                }}
            />
        </>
    )
}
