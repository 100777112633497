import React, { useState, useEffect } from 'react'
import { geocode, OutputFormat } from 'react-geocode'

export default function LocationComponent(props) {
    const [loc, setLoc] = useState<String>('')

    useEffect(() => {
        if ((props.lat, props.lng)) {
            geocode('latlng', props.lat + ',' + props.lng, {
                key: process.env.REACT_APP_GMAPS!,
                language: 'es',
                region: 'es',
                outputFormat: OutputFormat.JSON,
            }).then((response) => {
                console.log(response['results'][0]['formatted_address'])
                setLoc(response['results'][0]['formatted_address'])
            })
        }
    }, [props.lat, props.lng])

    return <>{loc}</>
}
